import React,{useEffect, useState} from 'react'
import Navbar from '../components/Layout/Navbar'
import Trackform from '../components/Track/Trackform'
import TrackingDetails from '../components/Tracking/TrackingDetails'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../firebase.config'
import Footer from '../components/Layout/Footer'

const Track = () => {
    const [code, setCode] = useState("")
    const [activeTrack, setActiveTrack] = useState("awb")
    const [orderDetails, setOrderDetails] = useState([])
    const handleTrack = async () =>{
        if(activeTrack === "awb"){
                const q = query(collection(db, "ecomOrder"), where("awbNumber", "==", code));
        const querySnapshot = await getDocs(q);
       querySnapshot.forEach((doc) => {
        setOrderDetails((prev)=>[...prev,{
         id:doc.id,
         ...doc.data()
        }])
        })
        console.log(orderDetails)
            }
        else if(activeTrack === "forwarding"){
                const q = query(collection(db, "ecomOrder"), where("forwarding", "==", code));
                const querySnapshot = await getDocs(q);
               querySnapshot.forEach((doc) => {
                setOrderDetails((prev)=>[...prev,{
                 id:doc.id,
                 ...doc.data()
                }])
                })
                console.log(orderDetails)
            }
        else{
                const q = query(collection(db, "ecomOrder"), where("orderID", "==", code));
                const querySnapshot = await getDocs(q);
               querySnapshot.forEach((doc) => {
                setOrderDetails((prev)=>[...prev,{
                 id:doc.id,
                 ...doc.data()
                }])
                })
                console.log(orderDetails)
            }
        }
        console.log(orderDetails)
        useEffect(() => {
        
        
        
        }, [code,activeTrack])
  return (
    <div className='bg-gray-50 h-[100vh]' >
    <Navbar />
        <Trackform handleTrack={handleTrack} code={code} setCode={setCode} activeTrack={activeTrack} setActiveTrack={setActiveTrack} />
      {orderDetails.length!==0 &&  <TrackingDetails orderDetails={orderDetails[0]} />}
   
    </div>
  )
}

export default Track