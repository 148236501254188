import React from 'react'
import Navbar from '../components/Layout/Navbar'
import AboutBanner from '../components/About/AboutBanner'
import AboutUs from '../components/Home/AboutUs'
import Footer from '../components/Layout/Footer'
import Mission from '../components/About/Mission'
import OurAssociates from '../components/About/OurAssociates'

const About = () => {
  return (
    <div>
        <Navbar />
        <AboutBanner />
        <AboutUs />
        <Mission />
        <OurAssociates />
        <Footer />
    </div>
  )
}

export default About