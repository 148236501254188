import React from 'react'
import Footer from '../components/Layout/Footer'
import Navbar from '../components/Layout/Navbar'

const Contactus = () => {
  return (
    <div>
    <Navbar />
    <p className='mt-[100px]'>
        You may contact us using the information below:
    <br/>
Merchant Legal entity name: GRC EXPRESS AND LOGISTICS PRIVATE LIMITED
<br/><br/>
Registered Address: A9/03, Broadway Avenue Bldg, RNA Complex, Mira Road Thane MAHARASHTRA 401107
<br/><br/>
Operational Address: A9/03, Broadway Avenue Bldg, RNA Complex, Mira Road Thane MAHARASHTRA 401107
<br/><br/>
Telephone No: 9820279081
<br/>
E-Mail ID: grclogistics6@gmail.com
</p>
<Footer />
    </div>
  )
}

export default Contactus