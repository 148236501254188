import React from 'react'
import BannerImageOne from "../../asset/bannerCarousel1.svg"
import BannerImageTwo from "../../asset/bannerCarousel2.svg"
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import TrackingForm from './TrackingForm';
const BannerCarousel = ({activeTrack,setActiveTrack}) => {
  return (
    <div className='relative ' >
     <Swiper
          spaceBetween={0}
          className="mt-24 mb-8"
          draggable={true}
          slidesPerView={1}
          autoplay={{
            delay: 3000,
            disableOnInteraction:false,
          }}
          loop={true}
          modules={[Autoplay]}
         
        > 
        <SwiperSlide>
        <img src={BannerImageOne} className='w-[100vw]  ' />
        </SwiperSlide>
        <SwiperSlide>
        <img src={BannerImageTwo} className='w-[100vw] ' />
        </SwiperSlide>
        </Swiper>
       <TrackingForm activeTrack={activeTrack} setActiveTrack={setActiveTrack} />
    </div>
  )
}

export default BannerCarousel