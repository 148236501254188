import React,{useEffect} from 'react'
import Logistics from "../../asset/logistics.jpg"
import ServiceCard from './ServiceCard'
import { useScroll } from 'framer-motion';
import Lenis from '@studio-freight/lenis'
import { useRef } from 'react';
import Internatinal from "../../asset/international.jpg"
import Ecommerce from "../../asset/ecommerce.jpg"
import { ArrowUpIcon } from '@heroicons/react/24/outline';
const Services = ({activeService,setActiveService}) => {
  

 




useEffect( () => {

const lenis = new Lenis()



function raf(time) {

  lenis.raf(time)

  requestAnimationFrame(raf)

}



requestAnimationFrame(raf)

})
  return (
    <div className='px-12 py-12 '>
        <div>
            <h3 className='font-[InterBlack] leading-[3rem] text-[2rem] uppercase ' >Ecommerce Perfected <br /> <span
              style={{
                background:
                  "linear-gradient(to right, #ee0979 0%, #ff6a00 100%)",
                "-webkit-background-clip": "text",
                "-webkit-text-fill-color": "transparent",
              }}
              className="py-2 font-[InterBold]  inline"
            >
              Your Logistics, Our Commitment.
            </span> </h3>
            <p className='font-[InterMedium] text-[1.2rem] capitalize text-gray-400 my-6' >Efficient logistics for your success. <br /> <span className='font-[InterBold] text-[1.5rem] text-[#272727]' > Precision, reliability, and growth,</span>  delivered. </p>
            <div className='pt-0 grid gap-8 mt-8 grid-cols-3 grid-flow-col' >
              <div className='bg-gray-100   rounded-xl' >
              <img className='rounded-t-xl' src={Ecommerce} alt="" />
              <div className='px-4 pb-12 pt-4' >
              <h3 className='font-[InterBlack] my-3 text-[1.5rem] uppercase ' >Ecommerce Shipping</h3>

              <p className='font-[InterMedium]' >Simplify your e-commerce journey with our top-notch shipping solutions. We handle the logistics; you enjoy business growth hassle-free</p>
              <div className='flex items-center gap-2  justify-start'>
              <p className='text-[1rem]  font-[InterMedium] mt-4' >Explore Now  </p>
              <ArrowUpIcon className='w-[20px] rotate-45 mt-4' />
              </div>
              </div>
              </div>
              <div className='bg-gray-100   rounded-xl' >
              <img className='rounded-t-xl' src={Logistics} alt="" />
              <div className='px-4 pb-12 pt-4' >
              <h3 className='font-[InterBlack] my-3 text-[1.5rem] uppercase ' >Logistics</h3>

              <p className='font-[InterMedium]' >Simplify your e-commerce journey with our top-notch shipping solutions. We handle the logistics; you enjoy business growth hassle-free</p>
              <div className='flex items-center gap-2  justify-start'>
              <p className='text-[1rem]  font-[InterMedium] mt-4' >Explore Now  </p>
              <ArrowUpIcon className='w-[20px] rotate-45 mt-4' />
              </div>
              </div>
              </div>
              <div className='bg-gray-100   rounded-xl' >
              <img className='rounded-t-xl' src={Internatinal} alt="" />
              <div className='px-4 pb-12 pt-4' >
              <h3 className='font-[InterBlack] my-3 text-[1.5rem] uppercase ' >International</h3>

              <p className='font-[InterMedium]' >Simplify your e-commerce journey with our top-notch shipping solutions. We handle the logistics; you enjoy business growth hassle-free</p>
              <div className='flex items-center gap-2  justify-start'>
              <p className='text-[1rem]  font-[InterMedium] mt-4' >Explore Now  </p>
              <ArrowUpIcon className='w-[20px] rotate-45 mt-4' />
              </div>
              </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Services