import React from 'react'

const Mission = () => {
  return (
    <div className='mx-12 grid grid-cols-2 grid-flow-col gap-12 py-12 rounded-lg px-12 my-8'>
        <div className='bg-gray-200 py-8 px-12' >
                <h3 className='font-[InterBold] text-[1.5rem]' >Mission</h3>
                <p className='font-[InterMedium]' >Transforming Logistics, Connecting Businesses Worldwide with Swift, Reliable, and Innovative Shipping Solutions</p>
        </div>
        <div className='bg-gray-200 py-8 px-12'>
                <h3 className='font-[InterBold] text-[1.5rem]'>Vision</h3>
                <p className='font-[InterMedium]'>GRC Express envisions a borderless logistics future, connecting businesses globally through innovative and reliable solutions.</p>
        </div>
    </div>
  )
}

export default Mission