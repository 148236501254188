import React from 'react'

const AboutUs = () => {
  return (
    <div className='mx-12 py-12 rounded-lg px-12 my-8' >
    <div className='grid grid-cols-2 items-center justify-center gap-20 grid-flow-col' >
      <div className='col-span-1' >
        <h3 className='font-[InterBlack] uppercase text-[#272727] text-[2rem]' > Shipping, Logistics, <br />  <span
              style={{
                background:
                  "linear-gradient(to right, #ee0979 0%, #ff6a00 100%)",
                "-webkit-background-clip": "text",
                "-webkit-text-fill-color": "transparent",
              }}
              className="py-2 font-[InterBold]  inline"
            >
              E-commerce Solutions.
            </span> </h3>
        <p className='font-[InterRegular] mt-6 text-justify' >
Welcome to GRC Express and Logistics, your trusted partner in international courier, logistics, and warehousing. <br /> With 20 years of industry expertise, we have strategically  located  offices in Borivali, Bhiwandi, and Vasai, supported by a dedicated team.  </p>
      </div>
      <div className='col-span-1' >
        <img src='https://img.freepik.com/free-photo/young-delivery-men-showing-ok-sign-near-delivery-car_23-2148944557.jpg?w=996&t=st=1707431917~exp=1707432517~hmac=6a5086ac0af995784a52a6c007afa40689d586a97f11268fbdc343ff1f3752d2' />
      </div>
      </div>
    </div>
  )
}

export default AboutUs