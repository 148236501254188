import React from 'react'
import Logo from "../../asset/grc-logo.png"
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <div className='py-16  px-12  bg-[#272727]' >
      <div className='grid grid-cols-4 gap-16 grid-flow-col' >
        <div>
          <img className='lg:w-[120px] w-[80px]' src={Logo} alt="GRC logo"/>
          <p className='mt-4 text-white font-[InterMedium]' >As leaders in logistics, we provide a tech-enabled courier service ensuring safe, secure, and timely deliveries.</p>
        </div>
        <div className='flex items-center justify-start flex-col' >
        <div className='flex items-start justify-start flex-col'>
          <p className='text-white font-[InterBold] text-[1.5rem]' >Important Links</p>
          <p className='text-gray-200  font-[InterRegular] text-[1rem]' >Home</p>
          <p className='text-gray-200  font-[InterRegular] text-[1rem]' >About</p>
          <p className='text-gray-200  font-[InterRegular] text-[1rem]' >Track</p>
        <Link to="/contactus" >  <p className='text-gray-200  font-[InterRegular] text-[1rem]' >Contact</p></Link>
          </div>
        </div>
        <div className='flex items-center justify-start flex-col' >
        <div className='flex items-start justify-start flex-col'>
          <p className='text-white font-[InterBold] text-[1.5rem]' >Our Services</p>
          <p className='text-gray-200  font-[InterRegular] text-[1rem]' >Ecommerce</p>
          <p className='text-gray-200  font-[InterRegular] text-[1rem]' >Logistics</p>
          <p className='text-gray-200  font-[InterRegular] text-[1rem]' >International</p>
         
          </div>
        </div>
        <div className='flex items-center justify-start flex-col' >
        <div className='flex items-start justify-start flex-col'>
          <p className='text-white font-[InterBold] text-[1.5rem]' >Others</p>
         <Link to="/privacy-policy" ><p className='text-gray-200  font-[InterRegular] text-[1rem]' >Privacy Policy</p></Link> 
       <Link to="/terms-condition" > <p className='text-gray-200  font-[InterRegular] text-[1rem]' >Terms and Condition</p></Link>  
       <Link to="/shipping-delivery" > <p className='text-gray-200  font-[InterRegular] text-[1rem]' >Shipping and Delivery</p></Link>
         
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer