import React from 'react'

const TrackingDetails = ({orderDetails}) => {
  return (
    <div className='py-12 px-16 h-[100vh] mt-[100px]' >
        <h3 className='font-[InterBlack] uppercase text-[2rem]' >Your Order Information Is:</h3>
        <div className='bg-gray-100 mt-12 py-8 px-16' >
            <div className='border-b-[1px] py-6 border-gray-300' >
               <p className='font-[InterMedium] text-[1.5rem]' > Order Details: </p>
               <div>
                <p>Order ID: #{orderDetails?.orderID}</p>
                <p>AWB Number: {orderDetails?.awbNumber}</p>
                <p>Order Status:<span className='font-[InterBold] uppercase text-green-400' > {orderDetails?.orderStatus}</span></p>
               </div>
            </div>
            <div className='grid grid-cols-2 py-6 grid-flow-col' >
            <div>
                <h3 className='font-[InterBold] text-[1.4rem]' >Consignee Details</h3>
                <div>
                    <p className='font-[InterRegular]' ><span className='font-[InterMedium]' >Consignee Name:</span>  {orderDetails?.consigneeData?.consigneeName}</p>
                    <p className='font-[InterRegular]' ><span className='font-[InterMedium]' >Consignee Email:</span>  {orderDetails?.consigneeData?.consigneeEmail}</p>
                    <p className='font-[InterRegular]' ><span className='font-[InterMedium]' >Consignee Contact:</span>  {orderDetails?.consigneeData?.consigneePhone}</p>
                </div>
            </div>
            <div>
                <h3 className='font-[InterBold] text-[1.4rem]' >Shipper Details</h3>
                <div>
                    <p className='font-[InterRegular]' ><span className='font-[InterMedium]' >Shipper Name:</span>  {orderDetails?.shipperData?.shipperName}</p>
                    <p className='font-[InterRegular]' ><span className='font-[InterMedium]' >Shipper Email:</span>  {orderDetails?.shipperData?.shipperEmail}</p>
                    <p className='font-[InterRegular]' ><span className='font-[InterMedium]' >Shipper Contact:</span>  {orderDetails?.shipperData?.shipperContact}</p>
                </div>
            </div>

            </div>
        </div>
    </div>
  )
}

export default TrackingDetails