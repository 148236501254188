import React from 'react'
import AboutBannerImage from "../../asset/aboutBanner.svg"
const AboutBanner = () => {
  return (
    <div className="mt-24 mb-8"  >
        <img className='w-[100vw]' src={AboutBannerImage} />
    </div>
  )
}

export default AboutBanner