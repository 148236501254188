import React,{useState} from 'react'
import Navbar from '../components/Layout/Navbar'
import BannerCarousel from '../components/Home/BannerCarousel'
import WhyChooseUs from '../components/Home/WhyChooseUs'
import AboutUs from '../components/Home/AboutUs'
import Services from '../components/Home/Services'
import Footer from '../components/Layout/Footer'
import Associates from '../components/Home/Associates'
import TrackingForm from '../components/Home/TrackingForm'
import DHL from "../asset/logo/dhl.png"
import Fedx from "../asset/logo/fedx.png"
import BlueDart from "../asset/logo/bluedart.png"
import Ecomexpress from "../asset/logo/ecomexpress.png"
import Aramex from "../asset/logo/aramex.png"
import Delhivery from "../asset/logo/delhivery.png"
import Xpressbees from "../asset/logo/xpressbees.png"
import Testimonials from '../components/Home/Testimonials'
const Home = () => {
  const [activeService, setActiveService] = useState("ecommerce")
  const [activeTrack, setActiveTrack] = useState("awb")
  const images = [DHL, Fedx, BlueDart, Ecomexpress, Aramex, Delhivery, Xpressbees].map((image) => ({
  id: crypto.randomUUID(),
  image
}));

  return (
    <div>
      <Navbar />
      <div className='' >
      <BannerCarousel activeTrack={activeTrack} setActiveTrack={setActiveTrack} />
      <Associates images={images} />
      <WhyChooseUs />
     
      <Services activeService={activeService} setActiveService={setActiveService} />
      <Testimonials />
      <Footer />
      </div>
    </div>
  )
}

export default Home