import React from 'react'
import HourGlass from "../../asset/hourglass.png"
import Tracking from "../../asset/delivery.png";
import Proof from "../../asset/invoice.png";
import Insurance from "../../asset/insurance.png";
import CustomerService from "../../asset/customer-service.png";
import { MdSpeed } from "react-icons/md";
import { MdMyLocation } from "react-icons/md";
import { TbFileInvoice } from "react-icons/tb";
import { AiOutlineSafety } from "react-icons/ai";
import { MdOutlineSupportAgent } from "react-icons/md";
import { GrCheckboxSelected } from "react-icons/gr";
const WhyChooseUs = () => {
  return (
    <div className='px-12 py-12' >
        <div className='grid grid-cols-2 items-center justify-start grid-flow-col' > 
        <div className='col-span-1' >
            <h3 className='font-[InterBlack] uppercase text-[#272727] text-[2rem]' >Your Trusted Partner for <br />  <span
              style={{
                background:
                  "linear-gradient(to right, #ee0979 0%, #ff6a00 100%)",
                "-webkit-background-clip": "text",
                "-webkit-text-fill-color": "transparent",
              }}
              className="py-2 font-[InterBold]  inline"
            >
              Seamless  Logistics Solutions.
            </span> </h3>
            <p className='mt-3 font-[InterMedium]' > "Experience the epitome of logistics excellence with our brand. We pride <br /> ourselves on delivering seamless solutions that elevate your business operations. </p>
            <button className='mt-6 bg-blue-600 py-3 px-8 text-white font-[InterBold] rounded-lg' >Book Your First Shipment</button>
            </div>
            <div className='grid col-span-1 grid-cols-3 my-8 grid-flow-row gap-6' >
                <div className='flex items-center justify-center rounded-lg flex-col gap-4 py-8' >
                <MdSpeed className='text-[50px] text-blue-600 mb-2'  />
                <p className='font-[InterBold] uppercase lg:text-[.8rem] xl:text-[.9rem] text-[#272727]' >Fastest TAT</p>
                </div>
                <div className='flex items-center justify-center rounded-lg flex-col gap-4 py-8' >
                <MdMyLocation className='text-[40px] text-blue-600  mb-2'  />
                <p className='font-[InterBold] uppercase lg:text-[.8rem] xl:text-[.9rem] text-[#272727]' >Real Time Tracking</p>
                </div>
                <div className='flex items-center justify-center rounded-lg flex-col gap-4 py-8' >
                <TbFileInvoice className='text-[40px] text-blue-600  mb-2'  />
                <p className='font-[InterBold] uppercase lg:text-[.8rem] xl:text-[.9rem] text-[#272727]' >Proof of Delivery</p>
                </div>
                <div className='flex items-center justify-center rounded-lg flex-col gap-4 py-8' >
                <AiOutlineSafety className='text-[40px] text-blue-600  mb-2'  />
                <p className='font-[InterBold] uppercase lg:text-[.8rem] xl:text-[.9rem] text-[#272727]' >Insurance Coverage</p>
                </div>
                <div className='flex items-center justify-center rounded-lg flex-col gap-4 py-8' >
                <MdOutlineSupportAgent className='text-[40px] text-blue-600  mb-2'  />
                <p className='font-[InterBold] uppercase lg:text-[.8rem] xl:text-[.9rem] text-[#272727]' >Personalized Support</p>
                </div>
                <div className='flex items-center justify-center rounded-lg flex-col gap-4 py-8' >
                <GrCheckboxSelected  className='text-[40px] text-blue-600  mb-2'  />
                <p className='font-[InterBold] uppercase lg:text-[.8rem] xl:text-[.9rem] text-[#272727]' >Safety Measures</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default WhyChooseUs