import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
// Import Swiper styles
import "swiper/css";

import "./Home.css"
const Associates = ({images}) => {
  
  const speed = 9000
  return (
    <div className=' px-[10px]' >
  
    <div className="inner">
    
      <div className="wrapper">
        <section style={{ "--speed": `${speed}ms` }}>
          {images.map(({ id, image }) => (
            <div className="image rounded-xl shadow-gray-200 shadow-md" key={id}>
              <img  src={image} alt={id} />
            </div>
          ))}
        </section>
        <section style={{ "--speed": `${speed}ms` }}>
          {images.map(({ id, image }) => (
            <div  className="image rounded-xl shadow-gray-200 shadow-md" key={id}>
              <img src={image} alt={id} />
            </div>
          ))}
        </section>
        <section style={{ "--speed": `${speed}ms` }}>
          {images.map(({ id, image }) => (
            <div className="image rounded-xl shadow-gray-200 shadow-md" key={id}>
              <img src={image} alt={id} />
            </div>
          ))}
        </section>
      </div>
    </div>
       
    </div>
  )
}

export default Associates