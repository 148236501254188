import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
// Import Swiper styles
import "swiper/css";
const Testimonials = () => {
  return (
    <div className='px-12 py-8 ' >
    <div className='flex items-center justify-between' >
      <h3 className='uppercase text-[2rem] font-[InterBlack]' >What People has <span
              style={{
                background:
                  "linear-gradient(to right, #ee0979 0%, #ff6a00 100%)",
                "-webkit-background-clip": "text",
                "-webkit-text-fill-color": "transparent",
              }}
              className="py-2 font-[InterBold]  inline"
            >
              to Say About Us
            </span> </h3>
    </div>
         <Swiper
          spaceBetween={0}
          className="mt-24  mb-8"
          draggable={true}
          slidesPerView={1}
          autoplay={{
            delay: 3000,
            disableOnInteraction:false,
          }}
          loop={true}
          modules={[Autoplay]}
         
        > 
            <SwiperSlide>
              <div className='flex px-48 flex-col items-center justify-center' >
                <h3 className='text-center font-[InterMedium]' >
                Choosing GRC Ecom was a game-changer for our business. Their precision in handling logistics, coupled with unwavering reliability, has significantly streamlined our operations. From seamless transportation to timely deliveries, they've proven to be an invaluable partner in our success journey. Trustworthy, efficient, and highly recommended!
                </h3>
                <h2 className='font-[InterBold] text-[1.3rem] my-3' >Rohan Singh </h2>
                <p  className='font-[InterBold] text-gray-600 '>Logistics Manager | TCS</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='flex px-48 flex-col items-center justify-center' >
                <h3 className='text-center font-[InterMedium]' >
                Choosing GRC Ecom was a game-changer for our business. Their precision in handling logistics, coupled with unwavering reliability, has significantly streamlined our operations. From seamless transportation to timely deliveries, they've proven to be an invaluable partner in our success journey. Trustworthy, efficient, and highly recommended!
                </h3>
                <h2 className='font-[InterBold] text-[1.3rem] my-3' >Rajesh Lodha </h2>
                <p  className='font-[InterBold] text-gray-600 '>CEO | Lodha Group</p>
              </div>
            </SwiperSlide>
        </Swiper>
    </div>
  )
}

export default Testimonials