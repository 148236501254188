import React from 'react'
import DHL from "../../asset/logo/dhl.png"
import Fedx from "../../asset/logo/fedx.png"
import BlueDart from "../../asset/logo/bluedart.png"
import Ecomexpress from "../../asset/logo/ecomexpress.png"
import Aramex from "../../asset/logo/aramex.png"
import Delhivery from "../../asset/logo/delhivery.png"
import Xpressbees from "../../asset/logo/xpressbees.png"

const OurAssociates = () => {
  return (
    <div className='mx-12 py-12 rounded-lg px-12 my-8' >
        <div>
            <h3 className='font-[InterBold] text-[2rem] text-center' >Our Associates</h3>
            <div className='flex my-6 gap-6 flex-wrap items-center justify-center' >
                <div className='border-gray-300 border-[1px] shadow-sm rounded-lg py-8 px-8 w-[250px] ' >
                    <img  src={DHL} />
                </div>
                <div className='border-gray-300 border-[1px] shadow-sm rounded-lg py-8 px-8 w-[250px] ' >
                    <img  src={Fedx} />
                </div>
                <div className='border-gray-300 border-[1px] shadow-sm rounded-lg py-8 px-8 w-[250px] ' >
                    <img  src={BlueDart} />
                </div>
                <div className='border-gray-300 border-[1px] shadow-sm rounded-lg py-8 px-8 w-[250px] ' >
                    <img  src={Ecomexpress} />
                </div>
                <div className='border-gray-300 border-[1px] shadow-sm rounded-lg py-8 px-8 w-[250px] ' >
                    <img  src={Aramex} />
                </div>
                <div className='border-gray-300 border-[1px] shadow-sm rounded-lg py-8 px-8 w-[250px] ' >
                    <img  src={Delhivery} />
                </div>
                <div className='border-gray-300 border-[1px] shadow-sm rounded-lg py-8 px-8 w-[250px] ' >
                    <img  src={Xpressbees} />
                </div>
            </div>
        </div>
    </div>
  )
}

export default OurAssociates