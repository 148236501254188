
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Tracking from './pages/Tracking';
import About from './pages/About';
import Support from './pages/Support';
import Track from './pages/Track';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsandCondition from './pages/TermsandCondition';
import ShippingPolicy from './pages/ShippingPolicy';
import Contactus from './pages/Contactus';

function App() {
  return (
    <div className="App">
     <Routes>
     <Route exact path="/" element={<Home/>}   />
     <Route exact path="/about" element={<About />}   />
     <Route exact path="/support" element={<Support />}   />
     <Route exact path="/track" element={<Track />}   />
     <Route exact path="/tracking/:activeTrack/:code" element={<Tracking />}   />
     <Route exact path="/privacy-policy" element={<PrivacyPolicy />}   />
     <Route exact path="/terms-condition" element={<TermsandCondition />}   />
     <Route exact path="/shipping-delivery" element={<ShippingPolicy />}   />
     <Route exact path="/contactus" element={<Contactus />}   />
     </Routes>
    </div>
  );
}

export default App;
