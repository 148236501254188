import React,{useState,useEffect} from 'react'
import { collection, getDoc, getDocs, query, where } from 'firebase/firestore'
import { db } from '../firebase.config'
import { useParams } from 'react-router-dom'
import Navbar from '../components/Layout/Navbar'
import TrackingDetails from '../components/Tracking/TrackingDetails'
import Footer from '../components/Layout/Footer'

const Tracking = () => {
    const {code,activeTrack} = useParams()
    const [orderDetails, setOrderDetails] = useState([])
    const handleTrack = async () =>{
        if(activeTrack === "awb"){
                const q = query(collection(db, "ecomOrder"), where("awbNumber", "==", code));
        const querySnapshot = await getDocs(q);
       querySnapshot.forEach((doc) => {
        setOrderDetails((prev)=>[...prev,{
         id:doc.id,
         ...doc.data()
        }])
        })
        console.log(orderDetails)
            }
        else if(activeTrack === "forwarding"){
                const q = query(collection(db, "ecomOrder"), where("forwarding", "==", code));
                const querySnapshot = await getDocs(q);
               querySnapshot.forEach((doc) => {
                setOrderDetails((prev)=>[...prev,{
                 id:doc.id,
                 ...doc.data()
                }])
                })
                console.log(orderDetails)
            }
        else{
                const q = query(collection(db, "ecomOrder"), where("orderID", "==", code));
                const querySnapshot = await getDocs(q);
               querySnapshot.forEach((doc) => {
                setOrderDetails((prev)=>[...prev,{
                 id:doc.id,
                 ...doc.data()
                }])
                })
                console.log(orderDetails)
            }
        }
        console.log(orderDetails)
        useEffect(() => {
          handleTrack()
        
        
        }, [code,activeTrack])
        
  return (
    <div>
        <Navbar />
        <TrackingDetails orderDetails={orderDetails[0]} />
        <Footer />
    </div>
  )
}

export default Tracking